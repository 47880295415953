.modal-code{
  max-width: 226px;
}

.modal-code .ant-modal-footer{
  text-align: center;
}

.modal-code:hover .ant-input-affix-wrapper,
.modal-code:focus .ant-input-affix-wrapper {
  border-color: #BFC0C0;
}

.modal-code .ant-modal-footer button:hover:first-of-type {
  color: #918b65;
  border-color: #918b65;
}

.modal-code .ant-modal-footer button:last-of-type,  
.modal-code .ant-modal-footer button:hover:last-of-type  {
  background-color: #918b65;
}

.btn-detalle {
  height: 42px;
  width: 152px;
  font-family: 'Cinzel', serif;
  font-size: 13px;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid #BFC0C0;
  color: #BFC0C0;
  margin: 30px auto;
}

.btn-detalle:hover {
  border-color: #918b65 !important;
}

.btn-detalle:hover .ant-btn-loading-icon {
  color: #918b65;
}

.btn-detalle .titulo-boton {
  transform: translateX(8px);
  transition: .5s;
}

.btn-detalle:hover .titulo-boton {
  color: #918b65;
  opacity: 1;
  transition: .5s;
  transform: translateX(0px);
}

.btn-detalle i {
  opacity: 0;
  transform: translateX(18px);
  transition: .5s;
}

.btn-detalle:hover i {
  color: #918b65;
  opacity: 1;
  transition: .5s;
  transform: translateX(10px);
}



/* Button Styles */
/* 
.button {
  height: 40px;
  width: 150px;
  border: 2px solid #BFC0C0;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: .8em;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 30px auto;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Cinzel', serif;
}

#button-1 {
  color: #BFC0C0;
  text-decoration: none;
  letter-spacing: 1px;
  cursor: pointer;
}

#button-1:hover img {
  width: 15px;
  opacity: 1;
  transition: .5s;
  transform: translateX(45px);
}

#button-1 span {
  transition: .5s;
}

#button-1 img {
  width: 20px;
  height: 20px;
  filter: opacity(0.25);
}

#button-1:hover span {
  transform: translateX(-10px);
  transition: .5s;

}

#arrow-hover {
  width: 15px;
  height: 10px;
  position: absolute;
  transform: translateX(60px);
  opacity: 0;
  -webkit-transition: all .25s cubic-bezier(.14, .59, 1, 1.01);
  transition: all .15s cubic-bezier(.14, .59, 1, 1.01);
  margin: 0;
  padding: 0 5px;
} */