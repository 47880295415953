body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
}

.custom-notification .ant-notification-notice{
  padding: 20px 18px !important;
}

.custom-notification .ant-notification-notice-description{
  display: none !important;
}

.custom-notification .ant-notification-notice-with-icon{
  display: flex;
}
.custom-notification .ant-notification-notice-icon{
  margin: auto 0;
  position: relative !important;
}
.custom-notification .ant-notification-notice-message{
  margin: auto 0px auto 8px !important;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px !important;
  padding-right: 0px !important;
  user-select: none
}


@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500&family=Montserrat:wght@100;200&family=Outfit:wght@100;200;300;400;500&family=Quicksand:wght@300;400;500;600;700&family=The+Nautigal&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500&family=Montserrat:wght@100;200&family=Outfit:wght@100;200;300;400;500&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500&family=Outfit:wght@100;200;300;400;500&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Pacifico&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Kalam:wght@300;700&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Cinzel:wght@400;500;600;700;800;900&family=Kalam:wght@300;700&family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Cinzel:wght@400;500;600;700;800;900&family=Kalam:wght@300;700&family=Pacifico&family=The+Nautigal:wght@400;700&display=swap');