.crear-invitacion .ant-form-item-label{
  padding-bottom: 0px;
}

.crear-invitacion .ant-form-item{
  margin-bottom: 2px;
}

.crear-invitacion .ant-radio-group{
  margin-top: 2px;
}

.crear-invitacion .ant-form-item-control-input{
  text-align: center;
}

.crear-invitacion .botones{
  margin-top: 10px;
  width: 100%;
}

.crear-invitacion .titulo{
  padding: 15px 0 10px 0px;
  display: block;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.crear-invitacion .tabla{
  height: calc(100vh - 378px);
  overflow: auto;
}
