.counter{
  display: flex;
  height: 50px;
  color: #918b65;
  text-shadow: 0px 0px 45px #ffffff;
  font-family: 'Montserrat', sans-serif;
}

.counter-segment{
  display: grid;
  text-align: center;
}

.counter-segment span:first-of-type{
  font-size: 32px;
}

.counter-segment span:last-of-type{
  font-size: 10px;
  color: #727272;
}

.counter-divider{
  margin: auto 12px auto 12px;
  color: white;
}

@media (min-width: 340px) {

  .counter{
    height: 48px;
    font-size: 22px;
  }

  .counter-segment span:first-of-type{
    font-size: 34px;
  }
  
  .counter-segment span:last-of-type{
    font-size: 12px;
  }
}

@media (min-width: 500px) {
  .counter{
    height: 48px;
    font-size: 22px;
  }

  .counter-segment span:first-of-type{
    font-size: 40px;
  }
  
  .counter-segment span:last-of-type{
    font-size: 12px;
  }
}

@media (min-width: 800px) {
  .counter{
    height: 100px;
    font-size: 34px;
  }

  .counter-segment span:first-of-type{
    font-size: 60px;
  }
  
  .counter-segment span:last-of-type{
    font-size: 14px;
  }
}
