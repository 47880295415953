
.mensaje{
  display: grid;
  text-align: center;
  font-family: 'Ysabeau', sans-serif;
  font-family: 'Cinzel', serif;
  font-weight: 200;
  /* margin: 30px 100px; */
  margin: 30px 20px;
  max-width: 400px;
  font-size: 12px;
}

@media screen and (min-width: 400px) {
  .mensaje{
    font-size: 14px;
    margin: 30px auto;
    max-width: 350px;
  }
}

@media screen and (min-width: 700px) {
  .mensaje{
    font-size: 16px;
    margin: 30px auto;
    max-width: 500px;
    /* margin: 30px 170px; */
  }
}

@media screen and (min-width: 1000px) {
  .mensaje{
    font-size: 18px;
    /* margin: 30px 300px; */
  }
}