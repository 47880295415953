.title {
  margin: 20px;
  display: block;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

/* .confirmation .ant-table-thead {
  display: none;
} */

.nombre-invitado {
  font-weight: 700;
  display: block;
  text-align: center;
}

.contenedor-confirmacion-button {
  margin: 10px 0px;
  width: 100%;
}

.ant-space-item {
  margin: auto;
}


#root {
  background: #faf7f8;
  background: #fafafa;
}

.container-buttons {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-columns: repeat(17, 1fr);
  grid-template-rows: repeat(20, 1fr);
  width: 100%;
  height: 100%;
  grid-gap: 10px;
  position: absolute;
  /* opacity: 0.3; */
}


.grid-item0 {
  /* background-image: url("https://images.unsplash.com/photo-1507562534834-42ebd36b00ea?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=f712012a42c9e9d9163fd9a0c80fd66a&auto=format&fit=crop&w=1202&q=80"); */
  background-size: cover;
  background-position: center;
  background-position-y: 45%;
  /*3*/
  grid-column: 2/5;
  grid-row: 3/9;

  /*3*/
  grid-column: 1/18;
  grid-row: 1/5;
  border-radius: 7px;
}

/* 




.grid-item3 {
  background-image: url("https://images.unsplash.com/photo-1508097549463-97f74cb9a6aa?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=52c257bf60c788a1332419c74c6a8a01&auto=format&fit=crop&w=1350&q=80");
  background-size: cover;
  background-position: center;
  grid-column: 1/5;
  grid-row: 5/5;
} */

.grid-item1 {
  /* background-image: url("https://images.unsplash.com/photo-1507562534834-42ebd36b00ea?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=f712012a42c9e9d9163fd9a0c80fd66a&auto=format&fit=crop&w=1202&q=80"); */
  background-size: cover;
  background-position: center;
  background-position-y: 45%;
  /*3*/
  grid-column: 2/5;
  grid-row: 3/9;

  /*3*/
  grid-column: 2/7;
  grid-row: 4/11;
  border-radius: 7px;
  filter: grayscale(30%);
}

.grid-item2 {
  /* background-image: url("https://images.unsplash.com/photo-1534714259038-764cb4cf1d58?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=d07ea713fcf12221034dafd8f4ffe82b&auto=format&fit=crop&w=1350&q=80"); */
  background-size: cover;
  background-position: top;
  background-position-y: 15%;
  grid-column: 5/8;
  grid-row: 2/8;

  grid-column: 7/12;
  grid-row: 3/10;
  border-radius: 7px;
  filter: grayscale(30%);
}

.grid-item3 {
  /* background-image: url("https://images.unsplash.com/photo-1534719156993-f3c9448673bf?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=f089cfa06eb1cd7c0f654b39d8fdfd07&auto=format&fit=crop&w=1350&q=80"); */
  background-size: cover;
  background-position-y: 20%;
  background-position-x: 45%;
  grid-column: 8/11;
  grid-row: 3/9;

  grid-column: 12/17;
  grid-row: 4/11;
  border-radius: 7px;
  filter: grayscale(30%);
}

.grid-item4 {
  /* background-image: url("https://images.unsplash.com/photo-1515694581961-1fbdb6840a5f?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4267be6fb944c94b672e0b3ddb458286&auto=format&fit=crop&w=634&q=80"); */
  background-size: cover;
  background-position: right;
  background-position-y: 18%;
  background-position-x: 80%;
  grid-column: 2/5;
  grid-row: 9/16;

  grid-column: 2/7;
  grid-row: 11/18;
  border-radius: 7px;
  filter: grayscale(30%);
}

.grid-item5 {
  /* background-image: url("https://images.unsplash.com/photo-1534703580202-6123d4189ef6?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=5478b30dec2346f00a40ab259d162068&auto=format&fit=crop&w=634&q=80"); */
  background-size: cover;
  background-position: top;
  background-position-y: 20%;
  grid-column: 5/8;
  grid-row: 8/15;

  grid-column: 7/12;
  grid-row: 10/17;
  border-radius: 7px;
  filter: grayscale(30%);
}

.grid-item6 {
  /* background-image: url("https://images.unsplash.com/photo-1534759846116-5799c33ce22a?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=19c8355cd5aff72debf2f4720f4c7e09&auto=format&fit=crop&w=688&q=80"); */
  background-size: cover;
  background-position: top;
  background-position-y: 12%;
  background-position-x: 80%;
  grid-column: 8/11;
  grid-row: 16/9;

  grid-column: 12/17;
  grid-row: 18/11;
  border-radius: 7px;
  filter: grayscale(30%);
}

/* 
.grid-item8 {
  background-image: url("https://images.unsplash.com/photo-1534743901536-d184ed4194bc?ixlib=rb-0.3.5&s=21fd4555141d93425b8ee3f80f764e69&auto=format&fit=crop&w=634&q=80");
  background-size: cover;
  background-position: center;
  grid-column: 6/8;
  grid-row: 1/6;
} */

























/* body {
  background: linear-gradient(250deg, #7b2ff7, #f107a3);
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  font-family: sans-serif;
  text-align: center;
} */

.parent-1 {
  background: #ffffffc7;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  left: 22%;
  /* border: 1px solid #555 */
}

.parent-1 i.fa-church {
  color: #918b65;
}

.parent-1[data-isOpen="true"] {
  width: 92%;
  /* max-width: 400px; */
  height: 1000px;
  max-height: calc(100vh - 120px);
  transform: translate3d(-19.6%, 0px, 0px) !important;
  z-index: 1;
  background: #121319c2;
  /* transition: 0.1s; */
  transition: 0.3s;
}

.parent-1[data-isOpen="false"] {
  transition: 0.3s;
  cursor: pointer;
}

.parent-1[data-isOpen="true"] i.fa-church {
  display: none;
}

.parent-1 .child {
  width: 40px;
  height: 40px;
  background: #f107a3;
  border-radius: 50%;
  display: none;
}

.parent-1[data-isOpen="true"] .child {
  display: contents;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 31px;
}





.parent-2 {
  background: #ffffffc7;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  left: 50%;
  /* left: 0; */
  right: 0;
  margin-left: auto;
  margin-right: auto;
  /* margin-left: -22px; */
  /* border: 1px solid #555 */
}

.parent-2 i.fa-champagne-glasses {
  color: #918b65;
}

.parent-2[data-isOpen="true"] {
  width: 92%;
  /* max-width: 400px; */
  height: 1000px;
  max-height: calc(100vh - 120px);
  transform: translate3d(-45.9%, 0px, 0px) !important;
  z-index: 1;
  background: #121319c2;
}

.parent-2[data-isOpen="false"] {
  transition: 0.3s;
  cursor: pointer;
  left: 0;
  margin-left: auto !important;
}

.parent-2[data-isOpen="true"] i.fa-champagne-glasses {
  display: none;
}

.parent-2 .child {
  width: 40px;
  height: 40px;
  background: #f107a3;
  border-radius: 50%;
  display: none;
}

.parent-2[data-isOpen="true"] .child {
  display: contents;
}









.parent-3 {
  background: #ffffffc7;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  right: 22%;

  /* border: 1px solid #555 */
}

.parent-3 i.fa-gift {
  color: #918b65;
}

.parent-3[data-isOpen="true"] {
  width: 92%;
  /* max-width: 400px; */
  height: 1000px;
  max-height: calc(100vh - 120px);
  transform: translate3d(19.6%, 0px, 0px) !important;
  z-index: 1;
  background: #121319c2;

}

.parent-3[data-isOpen="false"] {
  transition: 0.3s;
  cursor: pointer;
}

.parent-3[data-isOpen="true"] i.fa-gift {
  display: none;
}

.parent-3 .child {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: none;
}

.parent-3[data-isOpen="true"] .child {
  display: contents;
}




















/* 
.parent-4 {
  width: 0px;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 60px;
  right: 22%;

}

.parent-4 i.fa-check {
  color: #918b65;
}

.parent-4[data-isOpen="true"] {
  width: 92%;
  height: 1000px;
  max-height: calc(100vh - 120px);
  transform: translate3d(19.6%, 0px, 0px) !important;
  z-index: 1;
  background: #121319c2;


}

.parent-4[data-isOpen="false"] {
  transition: 0.3s;
}

.parent-4[data-isOpen="true"] .trigger {
  display: none;
}

.parent-4 .child {
  width: 40px;
  height: 40px;
  background: #f107a3;
  border-radius: 50%;
  display: none;
}

.parent-4[data-isOpen="true"] .child {
  display: contents;
}

 */










.parent-4 {
  /* background: #ffffffc7; */
  width: 0px;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 47px;
  left: 50%;
  margin-left: -22px;
  /* border: 1px solid #555 */
}

.parent-4 i.fa-check {
  color: #918b65;
}

.parent-4[data-isOpen="true"] {
  width: 92%;
  /* max-width: 400px; */
  height: 1000px;
  max-height: calc(100vh - 120px);
  transform: translate3d(-45.9%, 0px, 0px) !important;
  z-index: 1;
  background: #121319c2;
  background: #121319c2;
}

.parent-4[data-isOpen="false"] {
  transition: 0.3s;
  cursor: pointer;
  /* left: 0; */
}

.parent-4[data-isOpen="true"] i.fa-check {
  display: none;
}

.parent-4 .child {
  width: 40px;
  height: 40px;
  background: #f107a3;
  border-radius: 50%;
  display: none;
}

.parent-4[data-isOpen="true"] .child {
  display: contents;
}








@media screen and (max-width: 300px) {
  .contenedor-confirmacion-button {
    display: grid;
  }

  .contenedor-confirmacion-button .ant-space-item,
  .contenedor-confirmacion-button .ant-space-item button {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .ver-ubicacion {
    font-size: 12px !important;
  }

  .parent-1 {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  .parent-2 {
    width: 30px;
    height: 30px;
    margin-left: -13px;
    font-size: 12px;
  }

  .parent-2[data-isOpen="true"],
  .parent-4[data-isOpen="true"] {
    transform: translate3d(-45.5%, 0px, 0px) !important;
  }

  .parent-3 {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  .parent-4 {
    font-size: 12px;
    margin-left: -15px;
    font-size: 12px;
  }
}

@media screen and (min-width: 360px) {

  .parent-2,
  .parent-4 {
    margin-left: -17px;
  }

  .parent-2,
  .parent-4 {
    margin-left: 0px;
  }

  .parent-2[data-isOpen="true"],
  .parent-4[data-isOpen="true"] {
    transform: translate3d(-50%, 0px, 0px) !important;
  }

  /* .parent-2[data-isOpen="false"], */
  .parent-4[data-isOpen="false"] {
    margin-left: -17px;
  }
}

/* 
@media screen and (min-width: 480px) {
  .parent-2 {
    margin-left: -20px;
  }
  .parent-2 {
    margin-left: 0px;
  }
  .parent-2[data-isOpen="true"] {
    transform: translate3d(-50%, 0px, 0px) !important;
  }
}


@media screen and (min-width: 600px) {
  .parent-2 {
    margin-left: 0px;
  }
  .parent-2[data-isOpen="true"] {
    transform: translate3d(-50%, 0px, 0px) !important;
  }
} */




/* 

body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--purple);
  --accent: var(--white);

  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--accent);
  padding-bottom: 100px;
  overflow: hidden;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
  margin: 100px 0 40px;
}

h2 {
  font-weight: 400;
  margin: 50px 0 10px;
}

p {
  margin: 0 0 30px 0;
  font-size: 18px;
}

footer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 15px 15px;
  backdrop-filter: brightness(90%) blur(20px);
  -webkit-backdrop-filter: brightness(90%) blur(20px);
  -moz-backdrop-filter: brightness(90%) blur(20px);
  mask-image: repeating-radial-gradient(
    circle at 50% 50% center,
    var(--background),
    var(--background) 20px,
    rgba(255, 255, 255, 0.3) 20.5px,
    rgba(255, 255, 255, 0.3) 21px
  );
  -webkit-mask-image: repeating-radial-gradient(
    circle at 50% 50%,
    var(--background),
    var(--background) 20px,
    rgba(255, 255, 255, 0.3) 20.5px,
    rgba(255, 255, 255, 0.3) 21px
  );
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  line-height: 14px;
}

footer svg {
  margin-right: 20px;
  position: relative;
}

footer a {
  text-decoration: none;
  color: var(--accent);
  position: relative;
}

code {
  font-family: input-mono, monospace;
  font-weight: 400;
  font-style: normal;
}

#progress {
  position: fixed;
  top: 20px;
  left: 20px;
  transform: rotate(-90deg);
}

circle {
  stroke-dashoffset: 0;
  stroke-width: 15%;
  fill: none;
}

.bg {
  stroke: var(--accent);
  opacity: 0.3;
}

#progress .indicator {
  stroke: var(--accent);
}

ul {
  display: flex;
  list-style: none;
  height: 300px;
  overflow-x: scroll;
  padding: 20px 0;
  flex: 0 0 600px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: #fff3;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: #fff3;
}

li {
  flex: 0 0 200px;
  background: var(--accent);
  margin: 0 20px 0 0;
}

li:last-of-type {
  margin: 0;
}

.refresh {
  padding: 5px;
  position: absolute;
  border: 1px dotted var(--accent);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.refresh path {
  fill: var(--accent);
}

.box {
  width: 200px;
  height: 200px;
  background: var(--accent);
} */

.container-initials {

  position: absolute;
  height: 65px;
  width: 100%;
}

.head-names {
  font-family: 'Cormorant Garamond', serif;
  z-index: 1;
  color: #918b65;
  font-size: 36px;
  font-size: 32px;
  font-weight: 500;
  width: 100%;
  text-align: center;
  line-height: 60px;
  font-family: 'The Nautigal', cursive;
  top: 0;
}

.head-names .rings{
  width: 20px;
  margin: 0px 8px -2px 12px;
  color: #555;
}

.head-names span:nth-child(2) {
  font-size: 12px;
  margin: 0 12px 0 14px;
}

@media (min-width: 450px) {

  .head-names {
    font-size: 39px;
  }

  .head-names span:nth-child(2) {
    font-size: 16px;
  }

}


@media screen and (orientation: landscape) {
  .head-names {
    line-height: normal;
    font-size: 30px;
  }

}

/* @media screen and (orientation: landscape) and (min-width: 800px) {
  .contenido {
    overflow: auto;
height: 140px;
  }

} */

.contenido {
  display: grid;
  color: white;
  text-align: center;
  padding: 0px 30px 5px 30px;
  height: 100%;
  overflow: auto;
  border-radius: 50px;
}

/* @media (min-width: 600px) {

  .head-names .rings{
    width: 20px !important;
  }
} */

/* @media (min-width: 820px) {
  .head-names .rings{
    width: 28px !important;
  }

} */

/* 
@media (min-width: 600px) {

  .head-names{
    font-size: 45px;
    top: 4%;
  }

  .head-names span:nth-child(2){
    font-size: 20px;
  }

}

@media (min-width: 700px) {

  .head-names{
    top: 5%;
  }

  .head-names span:nth-child(2){
    font-size: 25px;
  }
}

@media (min-width: 820px) {

  .head-names{
    font-size: 55px;
    top: 6%;
  }

}

@media (min-width: 920px) {

  .head-names{
    font-size: 60px;
    top: 8%;
  }

} */










.child-container {
  font-family: 'Cinzel', serif;
  display: grid;
  color: white;
  text-align: center;
  padding: 11px 30px 10px 30px;
  height: 90%;
  overflow: auto;
  border-radius: 50px;
  user-select: none
}

.child .titulo {
  font-weight: 700;
  display: block;
  font-size: 22px;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.child .subtitulo {
  font-size: 14px;
  font-weight: 700;
  margin: 15px 0px 7px 0px;
}

.ver-ubicacion {
  margin: 10px auto;
  background: white;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  width: 170px;
  font-size: 14px;
}

.ver-ubicacion i {
  color: black;
  margin-right: 8px;
}

.ver-ubicacion a {
  text-decoration: none;
  color: black;
  vertical-align: middle;
}


.display-grid {
  display: grid;
}

.marginTop-10 {
  margin-top: 10px;
}

.marginTop-20 {
  margin-top: 20px;
}

.marginTop-30 {
  margin-top: 30px;
}

.textAlign-justify {
  text-align: justify;
}

.fontSize-12 {
  font-size: 12px;
}

.fontSize-14 {
  font-size: 14px;
}


input,
textarea,
button,
select,
button,
a {
  -webkit-tap-highlight-color: transparent;
}


.confirmar-asistencia {
  font-family: 'Cinzel', serif;
  font-weight: 400;
  margin: 15px auto;
  display: block;
  width: 80%;
  max-width: 450px;
  color: #BFC0C0;
  border: 2px solid #BFC0C0;
}


.confirmar-asistencia:hover,
.confirmar-asistencia:active {
  color: #918b65 !important;
  border-color: #918b65 !important;
}

.confirmar-asistencia.ant-btn-default:not(:disabled):focus-visible {
  outline: #918b65;
}


.confirmation {
  margin: auto 10px;
  overflow-y: auto;
  height: 90%;
  border-radius: 10px;
  user-select: none;
}

.confirmation .title {
  color: white;
}

.confirmation .subtitle {
  margin: -15px 0px 20px 0px;
  display: block;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.confirmation .ant-btn {
  padding: 4px 8px;
}

.confirmation .ant-table {
  background: transparent;
  color: white;
}

.confirmation .ant-table-cell-row-hover {
  background: transparent !important;
}

.confirmation .ant-table-row:hover>td {
  background: transparent !important;
}


.confirmation .ant-table-empty,
.confirmation .ant-table-empty:hover,
.confirmation .ant-table-placeholder, 
.confirmation .ant-table-placeholder:hover{
  background: transparent !important;
}

.confirmation .ant-table-cell{
  border: none !important;
}

.contenedor-confirmacion-button span {
  text-transform: uppercase;
}

.contenedor-confirmacion-button .confirmacion-button {
  background: #ffffff30;
}


/* .contenedor-confirmacion-button .ant-btn-default {
  background-color: palevioletred;
} */
.contenedor-confirmacion-button .confirmacion-button:hover,
.contenedor-confirmacion-button .confirmacion-button:active {
  color: #ffffff;
  border-color: #ffffff;
}

/* .contenedor-confirmacion-button .ant-btn-default:not(:disabled):focus-visible {
  outline: #dcdcdc !important;
  color: #ffffff;
} */






.btn-close-sections {
  position: absolute;
  top: -8px;
  z-index: 2;
  display: block;
  /* left: 50%;
  margin-left: -28px; */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #BFC0C0;
  border: 2px solid #BFC0C0;
}

.btn-close-sections:hover,
.btn-close-sections:active {
  color: #918b65 !important;
  border-color: #918b65 !important;
}

.estado-invitado {
  display: block;
  text-align: center;
}


table.cuentas{
  width: fit-content;
  margin: auto;
  /* user-select: all !important; */
}

table.cuentas td:nth-child(1){
  width: 10px;
}

table.cuentas td:nth-child(3){
  color: rgb(176 172 143);
  user-select: all !important;
  cursor: pointer;
}

