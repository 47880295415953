.crear-invitado .ant-form-item-label{
  padding-bottom: 0px;
}

.crear-invitado .ant-form-item{
  margin-bottom: 2px;
}

.crear-invitado .ant-radio-group{
  margin-top: 2px;
}

.crear-invitacion .ant-form-item-control-input{
  text-align: center;
}

.crear-invitado .botones{
  margin-top: 10px;
  width: 100%;
}

.crear-invitado .titulo{
  padding: 15px 0 10px 0px;
  display: block;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.crear-invitado .tabla{
  height: calc(100vh - 323px);
  overflow: auto;
}

.pendiente{
  padding: 2px 8px;
}

.si-asistire{
  background-color: #659174;
  color: #fff;
  border-radius: 7px;
  padding: 2px 8px;
}

.no-asistire{
  background-color: #b4756f;
  color: #fff;
  border-radius: 7px;
  padding: 2px 8px;
}

.tabla .ant-table-empty,.tabla .ant-table-placeholder{
  background: transparent !important;
}

.tabla .ant-table-cell{
  border: none !important;
}