
.portada{
  width: 100%;
  max-height: calc(100vh - 150px);
  min-height: 300px;
  object-fit: cover;
  display: block;
}

.initials{
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  z-index: 1;
  color: #918b65;
  color: #564d0f;
  color: #706e5e;
  font-size: 36px;
  font-size: 32px;
  position: absolute;
  top: 3%;
  width: 100%;
  text-align: center;
  font-family: 'The Nautigal', cursive;
}

.initials .rings{
  width: 20px;
  margin: 0px 8px -2px 12px;
  color: #555;
}

.initials span:nth-child(2){
  font-size: 12px;
  margin: 0 12px 0 14px;
}

.fecha-boda{
  font-family: 'The Nautigal', cursive;
  width: 100%;
  position: absolute;
  top: 52px;
  top: 26px;
  text-align: center;
  color: #918b65;
  font-size: 10px;
}


@media (min-width: 450px) {

  .initials{
    font-size: 39px;
  }

  /* .initials .rings{
    width: 24px !important;
  } */

  .initials span:nth-child(2){
    font-size: 16px;
  }

  .fecha-boda{
    top: 34px;
    font-size: 11px;
  }
}

@media (min-width: 600px) {

  .initials{
    font-size: 45px;
    top: 4%;
  }

  .initials .rings{
    width: 24px !important;
  }

  .initials span:nth-child(2){
    font-size: 20px;
  }

  .fecha-boda{
    top: 32px;
    font-size: 12px;
  }
}

@media (min-width: 700px) {

  .initials{
    top: 5%;
  }



  .initials span:nth-child(2){
    font-size: 25px;
  }
}

@media (min-width: 820px) {

  .initials{
    font-size: 55px;
    top: 6%;
  }

    .initials .rings{
    width: 28px !important;
  }
}

@media (min-width: 920px) {

  .initials{
    font-size: 60px;
    top: 8%;
  }

}
